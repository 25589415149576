<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="4" md="4" sm="6" xs="6">
                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    id="fiscal_year_id"
                                    plain
                                    v-model="formData.fiscal_year_id"
                                    :options="fiscalYearList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="6" xs="6">
                            <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="season_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('dae_config.season_name') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    id="season_id"
                                    plain
                                    v-model="formData.season_id"
                                    :options="seasonList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="6" xs="6">
                            <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="circular_type"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('dae_config.circular_type') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      id="circular_type"
                                      plain
                                      v-model="formData.circular_type"
                                      :options="circularTypeList"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                  </b-form-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="6" xs="6">
                            <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="circular_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('dae_subsidy.circular') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    id="circular_id"
                                    plain
                                    v-model="formData.circular_id"
                                    :options="circularList"
                                    @change="changeCircular(formData.circular_id)"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" md="4" sm="6" xs="6">
                            <ValidationProvider>
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="circular_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('dae_subsidy.circular') }}  {{ $t('dae_config.amount') }}
                                    </template>
                                    <b-form-input
                                      id="circular_id"
                                      plain
                                      v-model="circularAmount"
                                      :disabled="true"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                      </b-row>
                      <template>
                        <div style="font-size:14px; background-color: #66CC99; padding:6px">
                            <h5 class="text-white text-center"> {{ $t('dae_config.cost_per_kg') }}</h5>
                        </div>
                        <b-row>
                          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <ValidationProvider name="Transport Cost" vid="transport_cost" :rules="customRequired">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="transport_cost"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('dae_config.transport_cost') + $t('globalTrans.tk') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  type="text"
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  id="transport_cost"
                                  :placeholder="$t('dae_config.transport_cost')"
                                  v-model="formData.transport_cost"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <ValidationProvider name="Others Cost" vid="others_cost" :rules="customRequired">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="others_cost"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('dae_config.others_cost') + $t('globalTrans.tk') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  type="text"
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  id="others_cost"
                                  :placeholder="$t('dae_config.others_cost')"
                                  v-model="formData.others_cost"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-if="cashAllocationPerPersonShow || formData.circular_type === 2|| formData.circular_type === 3">
                        <template>
                          <div style="font-size:14px; background-color: #66CC99; padding:6px">
                              <h5 class="text-white text-center"> {{ $t('dae_config.cash_allocation_per_person') }}</h5>
                          </div>
                        </template>
                        <b-row>
                          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <ValidationProvider name="Total Farmer" vid="total_farmer" :rules="customRequired">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="total_farmer"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('dae_config.total_farmer') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  type="text"
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  id="total_farmer"
                                  placeholder="Total Farmer"
                                  v-model="formData.total_farmer"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <ValidationProvider name="Amount Per Farmer" vid="amount_per_farmer" :rules="customRequired">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="amount_per_farmer"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('dae_config.amount_per_farmer') + $t('globalTrans.tk') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  type="text"
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  id="amount_per_farmer"
                                  placeholder="Amount Per Farmer"
                                  v-model="formData.amount_per_farmer"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <ValidationProvider name="Total Amount" vid="total_amount" :rules="customRequired">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="total_amount"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{ $t('dae_config.total_amount') + $t('globalTrans.tk') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  type="text"
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  id="total_amount"
                                  placeholder="Total Amount"
                                  v-model="formData.total_amount"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :readonly="true"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                       </template>
                      <template>
                        <div style="font-size:14px; background-color: #66CC99; padding:6px">
                            <h5 class="text-white text-center"> {{ $t('dae_config.crop_assistance') }}</h5>
                        </div>
                      </template>
                      <b-row v-for="(detail, index) in formData.crop_details" :key="index" class="mt-3">
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Crop Name" vid="`crop_id$(index)`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="`crop_id$(index)`"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('dae_config.crop_name') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="crop_id"
                                    plain
                                    :value="detail.crop_name"
                                    :readonly="true"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Quantity" vid="`qnty$(index)`" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="`qnty$(index)`"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('dae_config.qnty') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                type="number"
                                id="`qnty$(index)`"
                                :placeholder="$t('dae_config.qnty')"
                                v-model="detail.qnty"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <template>
                        <div style="font-size:14px; background-color: #66CC99; padding:6px" class="mt-4">
                            <h5 class="text-white text-center"> {{ $t('dae_config.agricultural_assistance') }}</h5>
                        </div>
                      </template>
                      <b-row v-for="(info, index1) in formData.mat_details" :key="'un'+index1+1" class="mt-3">
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <ValidationProvider name="Agricultural Material Name" vid="`material_id$(index1)`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="`material_id$(index1)`"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('dae_config.mat_name') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="`material_id$(index1)`"
                                    plain
                                    :value="info.material_name"
                                    :readonly="true"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Quantity" vid="`quantity$(index1)`" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="`quantity$(index1)`"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('dae_config.qnty') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                :placeholder="$t('dae_config.qnty')"
                                type="number"
                                id="`quantity$(index1)`"
                                v-model="info.quantity"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                          <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                              <b-button type="submit" variant="primary" class="mr-2" v-if="saveBtn">{{ saveBtnName }}</b-button>
                              &nbsp;
                              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                          </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distributionPolicyStore, distributionPolicyUpdate, distriCirCropMaterial } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getDistributionPolicy()
      this.tmpFrmVal = {
        circular_type: tmp.circular_type,
        circular_id: tmp.circular_id,
        total_farmer: tmp.total_farmer,
        amount_per_farmer: tmp.amount_per_farmer,
        total_amount: tmp.total_amount,
        transport_cost: tmp.transport_cost,
        others_cost: tmp.others_cost
      }
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      cashAllocationPerPersonShow: false,
      customRequired: '',
      tmpFrmVal: {
        circular_type: '',
        circular_id: '',
        total_farmer: '',
        amount_per_farmer: '',
        total_amount: '',
        transport_cost: '',
        others_cost: ''
      },
      formData: {
        fiscal_year_id: 0,
        season_id: 0,
        circular_type: 0,
        circular_id: 0,
        total_farmer: '',
        amount_per_farmer: '',
        total_amount: '',
        transport_cost: '',
        others_cost: '',
        crop_details: [
          {
            crop_name: '',
            crop_id: 0,
            qnty: ''
          }
        ],
        mat_details: [
          {
            material_name: '',
            material_id: 0,
            quantity: ''
          }
        ]
      },
      circularList: [],
      cropNameList: [],
      materialList: [],
      circularAmount: 0,
      saveBtn: true
    }
  },
  computed: {
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    seasonList: function () {
      return this.$store.state.incentiveGrant.commonObj.seasonList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    circularTypeList: function () {
      const dataList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.filter(ct => ct.value === 2 || ct.value === 3)
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    agMaterialTypeList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.filter(item => item.status === 1)
      return objectData.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
        return { value: obj.value, text: obj.text_bn }
        } else {
        return { value: obj.value, text: obj.text_en }
        }
      })
    }
  },
  watch: {
    'formData.circular_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.circularList = this.getCircularList(newVal)
      }
    },
    'formData.circular_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setCircularAmount(newVal)
        // this.getCircularCropMaterialList(newVal)
        // this.getCashAllocationDataSet(newVal)
      }
    },
    'formData.total_farmer': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.formData.total_amount = this.formData.total_amount
        this.getSumTotalAmt()
      }
    },
    'formData.amount_per_farmer': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getSumTotalAmt()
      }
    }
  },
  methods: {
    changeCircular (circularId) {
      this.setCircularAmount(circularId)
      this.getCircularCropMaterialList(circularId)
      this.getCashAllocationDataSet(circularId)
    },
    getDistributionPolicy () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      this.formData.crop_details = tmpData.crop_details.map(item => {
        const crop = this.$store.state.incentiveGrant.commonObj.cropList.find(crp => crp.value === item.crop_id)
        return Object.assign(item, { crop_name: this.$i18n.locale === 'bn' ? crop.text_bn : crop.text_en })
      })
      this.formData.mat_details = tmpData.mat_details.map(matItem => {
        const mat = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(tmp => tmp.value === matItem.material_id)
        return Object.assign(matItem, { material_name: this.$i18n.locale === 'bn' ? mat.text_bn : mat.text_en })
      })
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${distributionPolicyUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(incentiveGrantServiceBaseUrl, distributionPolicyStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false

      if (result.success) {
        this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getCircularList (circularType) {
      const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.circular_type === circularType && item.fiscal_year_id === this.formData.fiscal_year_id)
      return circularList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    async getCircularCropMaterialList (circularId) {
      this.loading = true
      const circularObj = this.$store.state.incentiveGrant.commonObj.circularList.find(item => item.value === circularId)
      if (circularObj.is_cash_amount) {
        this.cashAllocationPerPersonShow = true
        this.customRequired = 'required'
      } else {
        this.cashAllocationPerPersonShow = false
        this.customRequired = ''
      }
      await RestApi.getData(incentiveGrantServiceBaseUrl, `${distriCirCropMaterial}/${circularId}`)
      .then(response => {
        if (response.success) {
          this.formData.crop_details = this.getCropDropdown(response.crops)
          this.formData.mat_details = this.getMaterialDropdown(response.materials)
        } else {
          this.formData.crop_details = []
          this.formData.mat_details = []
        }
      })
      this.loading = false
    },
    getCropDropdown (data) {
      return data.map(item => {
        return { crop_id: item.value, qnty: '', crop_name: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en }
      })
    },
    getMaterialDropdown (data) {
      return data.map(item => {
        return { material_id: item.value, quantity: '', material_name: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en }
      })
    },
    getSumTotalAmt () {
      const totalAmount = (this.formData.total_farmer ? this.formData.total_farmer : 1) * (this.formData.amount_per_farmer ? this.formData.amount_per_farmer : 1)
      if (totalAmount !== this.circularAmount) {
        this.saveBtn = false
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('dae_config.farmer_amount_circular_amount')
        })
      } else {
        this.saveBtn = true
        this.formData.total_amount = totalAmount
      }
    },
    getCashAllocationDataSet (cirId) {
      if (this.id && this.tmpFrmVal.circular_id === cirId) {
        this.formData.total_farmer = this.tmpFrmVal.total_farmer
        this.formData.amount_per_farmer = this.tmpFrmVal.amount_per_farmer
        this.formData.total_amount = this.tmpFrmVal.total_amount
        this.formData.transport_cost = this.tmpFrmVal.transport_cost
        this.formData.others_cost = this.tmpFrmVal.others_cost
      } else {
        this.formData.total_farmer = ''
        this.formData.amount_per_farmer = ''
        this.formData.total_amount = ''
        this.formData.transport_cost = ''
        this.formData.others_cost = ''
      }
    },
    setCircularAmount (circularId) {
      const circular = this.$store.state.incentiveGrant.commonObj.circularList.find(el => el.value === circularId)
      this.circularAmount = typeof circular !== 'undefined' ? circular.amount : 0
    }
  }
}
</script>
