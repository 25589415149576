
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_config.distribution_policy') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.fiscal_year')"
                            label-for="fiscal_year_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="3"
                          :label="$t('dae_config.season_name')"
                          label-for="season_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.season_id"
                              :options="seasonList"
                              id="season_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="3"
                          :label="$t('dae_config.circular_type')"
                          label-for="circular_type"
                          >
                          <b-form-select
                              plain
                              v-model="search.circular_type"
                              :options="circularTypeList"
                              id="circular_type"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                      <b-form-group
                          class="row"
                          label-cols-sm="3"
                          :label="$t('dae_config.circular_name')"
                          label-for="circular_id"
                          >
                          <b-form-select
                              plain
                              v-model="search.circular_id"
                              :options="circularList"
                              id="circular_id"
                              >
                              <template v-slot:first>
                              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('dae_config.distribution_policy_list') }} </h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                          <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                          <b-row>
                              <b-col md="12" class="table-responsive">
                                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                      <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(total_amount)="data">
                                          {{ $n(data.item.total_amount)  }}
                                      </template>
                                      <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                          <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <!-- <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                        <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                            <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                        </b-button> -->
                                        <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                              <i class="fas fa-eye"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                              <i class="ri-ball-pen-fill"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_view" title="Status" @click="remove(data.item)" v-if="data.item.status === 1">
                                              <i class="fas fa-toggle-on"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" @click="remove(data.item)" v-if="data.item.status === 2">
                                              <i class="fa fa-toggle-off"></i>
                                          </a>
                                      </template>
                                  </b-table>
                                  <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                      />
                              </b-col>
                          </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('dae_config.distribution_policy_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="dtlsItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distributionPolicyList, distributionPolicyStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        season_id: 0,
        circular_type: 0,
        circular_id: 0
      },
      rows: [],
      circularList: [],
      item: '',
      dtlsItemId: ''
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    seasonList: function () {
      return this.$store.state.incentiveGrant.commonObj.seasonList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('dae_config.distribution_policy') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_config.distribution_policy') + ' ' + this.$t('globalTrans.modify')
    },
    fiscalYearList: function () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalYearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    circularTypeList: function () {
      const dataList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
          { label: this.$t('dae_config.season_name'), class: 'text-center' },
          { label: this.$t('dae_config.circular_type'), class: 'text-center' },
          { label: this.$t('dae_config.circular_name'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_name_bn' },
          { key: 'season_name_bn' },
          { key: 'circular_type_name_bn' },
          { key: 'circular_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_name' },
          { key: 'season_name' },
          { key: 'circular_type_name' },
          { key: 'circular_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    'search.circular_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.circularList = this.getCircularList(newVal)
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
      this.dtlsItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, distributionPolicyStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, distributionPolicyList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const circularTypeList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList
      const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1)
      const listData = data.map(item => {
        const fiscalYearObj = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
        const seasonList = this.$store.state.incentiveGrant.commonObj.seasonList.find(season => season.value === item.season_id)
        const circularTypeObj = circularTypeList.find(ct => ct.value === item.circular_type)
        const circularObj = circularList.find(circular => circular.value === item.circular_id)
        const generateData = {
          fiscal_year_name: fiscalYearObj !== undefined ? fiscalYearObj.text_en : '',
          fiscal_year_name_bn: fiscalYearObj !== undefined ? fiscalYearObj.text_bn : '',
          season_name: seasonList !== undefined ? seasonList.text_en : '',
          season_name_bn: seasonList !== undefined ? seasonList.text_bn : '',
          circular_type_name: circularTypeObj !== undefined ? circularTypeObj.text_en : '',
          circular_type_name_bn: circularTypeObj !== undefined ? circularTypeObj.text_bn : '',
          circular_name: circularObj !== undefined ? circularObj.text_en : '',
          circular_name_bn: circularObj !== undefined ? circularObj.text_bn : ''
        }
        return Object.assign({}, item, generateData)
      })
      return listData
    },
    getCircularList (circularType) {
      const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.circular_type === circularType && item.fiscal_year_id === this.search.fiscal_year_id)
      return circularList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    }
  }
}
</script>
