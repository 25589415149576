<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                     <b-col lg="12" sm="12">
                        <div>
                        <b-col lg="12" sm="12" class="text-center">
                          <b-table-simple striped bordered small class="mt-3">
                              <b-tr>
                                <b-th>{{ $t('globalTrans.fiscal_year') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn')? formData.fiscal_year_name_bn : formData.fiscal_year_name }}</b-td>
                                <b-th>{{ $t('dae_config.season_name') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn')? formData.season_name_bn : formData.season_name }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('dae_config.circular_type') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn')? formData.circular_type_name_bn : formData.circular_type_name }}</b-td>
                                <b-th>{{ $t('dae_config.circular_name') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn')? formData.circular_name_bn : formData.circular_name }}</b-td>
                              </b-tr>
                          </b-table-simple>
                          </b-col>
                        </div>
                        <b-overlay :show="loading">
                          <div>
                            <b-col lg="12" sm="12" class="text-center">
                                <h4>{{ $t('dae_config.cost_per_kg') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('dae_config.transport_cost') + $t('globalTrans.tk') }} </b-th>
                                        <b-td> {{ $n(formData.transport_cost, { useGrouping: false }) }}</b-td>
                                        <b-th>{{ $t('dae_config.others_cost') + $t('globalTrans.tk') }} </b-th>
                                        <b-td>{{ $n(formData.others_cost, { useGrouping: false }) }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <b-col lg="12" sm="12" class="text-center" v-if="isCashAmount">
                                <h4>{{ $t('dae_config.cash_allocation_per_person') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('dae_config.total_farmer') }} </b-th>
                                        <b-td> {{ $n(formData.total_farmer, { useGrouping: false }) }}</b-td>
                                        <b-th>{{ $t('dae_config.amount_per_farmer') }} </b-th>
                                        <b-td>{{ $n(formData.amount_per_farmer, { useGrouping: false }) }}</b-td>
                                        <b-th>{{ $t('dae_config.total_amount') + $t('globalTrans.tk') }} </b-th>
                                        <b-td>{{ $n(formData.total_amount, { useGrouping: false }) }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <b-col lg="12" sm="12" class="text-center">
                                <h4>{{ $t('dae_config.crop_assistance') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('dae_config.crop_name') }}</b-th>
                                        <b-th>{{ $t('dae_config.qnty') }}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(detail,index) in cropData" :key="index">
                                        <b-td>{{ $n(index+1) }}</b-td>
                                        <b-td>{{ ($i18n.locale === 'bn') ? detail.crop_name_bn : detail.crop_name }}</b-td>
                                        <b-td>{{ $n(detail.qnty, { useGrouping: false } ) }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <b-col lg="12" sm="12" class="text-center">
                                <h4>{{ $t('dae_config.agricultural_assistance') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th>{{ $t('dae_config.material_type') }}</b-th>
                                        <b-th>{{ $t('dae_config.mat_name') }}</b-th>
                                        <b-th>{{ $t('dae_config.qnty') }}</b-th>
                                    </b-tr>
                                    <b-tr v-for="(info,index) in matData" :key="index">
                                        <b-td>{{ $n(index+1) }}</b-td>
                                        <b-td>{{ ($i18n.locale === 'bn') ? info.type_name_bn : info.type_name }}</b-td>
                                        <b-td>{{ ($i18n.locale === 'bn') ? info.mat_name_bn : info.mat_name }}</b-td>
                                        <b-td>{{ $n(info.quantity, { useGrouping: false } ) }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                          </div>
                        </b-overlay>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.formData = this.item
    this.isCashAmount = this.checkCashAmount(this.item.circular_id)
    this.cropData = this.getRelatinalData(this.item.crop_details)
    this.matData = this.getMaterialRelational(this.item.mat_details)
  },
  data () {
    return {
      formData: [],
      slOffset: 1,
      loading: false,
      isCashAmount: false
    }
  },
  methods: {
      getRelatinalData (data) {
        this.loading = true
        const cropList = this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
        const listData = data.map(item => {
          const cropListObj = cropList.find(crop => crop.value === item.crop_id)
          const generateData = {
            crop_name: cropListObj !== undefined ? cropListObj.text_en : '',
            crop_name_bn: cropListObj !== undefined ? cropListObj.text_bn : ''
          }
          return Object.assign({}, item, generateData)
        })
        return listData
      },
      getMaterialRelational (data) {
        const materialTypeList = this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.filter(item => item.status === 1)
        const materialList = this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.status === 1)
        const listData = data.map(item => {
          const materialObj = materialList.find(material => material.value === item.material_id)
          const materialTypeObj = materialTypeList.find(materialType => materialType.value === materialObj.material_type_id)
          const generateData = {
            type_name: materialTypeObj !== undefined ? materialTypeObj.text_en : '',
            type_name_bn: materialTypeObj !== undefined ? materialTypeObj.text_bn : '',
            mat_name: materialObj !== undefined ? materialObj.text_en : '',
            mat_name_bn: materialObj !== undefined ? materialObj.text_bn : ''
          }
          return Object.assign({}, item, generateData)
        })
        this.loading = false
        return listData
      },
      checkCashAmount (circularId) {
        const circularObj = this.$store.state.incentiveGrant.commonObj.circularList.find(item => item.value === circularId)
        if (circularObj.is_cash_amount !== 0) {
          return true
        } else {
          return false
        }
      }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
